
















import ForwardActionModel from "./ForwardActionModel";
import { InputSetups } from "@/mixins/input-setups";

import { Component, VModel, Mixins, Prop, Emit } from 'vue-property-decorator';
import CheckChannelsList from "@/components/Post/CheckChannelsList/CheckChannelsList.vue";
import { ValidationProvider } from "vee-validate";
import Validation from "piramis-base-components/src/components/Pi/components/Validation.vue";

@Component({
  components: {
    CheckChannelsList,
    ValidationProvider,
    Validation
  }
})
export default class ForwardActionView extends Mixins(InputSetups) {
  @VModel() model!: ForwardActionModel

  @Prop() disabled!: boolean
}
